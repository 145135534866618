@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



// Responsive font size mixin

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin responsiveFontSize($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$min_width: 460px;
$max_width: 2200px;
$min_font: 20px;
$max_font: 32px;

html {
  @include responsiveFontSize($min_width, $max_width, $min_font, $max_font);
}


// CUSTOM

body {
	transition: background-color 1s ease, color 1s ease;
	font-feature-settings: "liga", "onum";
	background: $black;
	color: $white;

	&.menu-open {
		overflow: hidden;

		section.page {
			filter: blur(5px);
		}
	}
}

@media (min-width: 2200px) {
	.grid-x {
	  max-width: 2200px;
    margin-left: auto;
    margin-right: auto;
	}
}

.grid-y--center {
  height: 100%;
  min-height: inherit;
  justify-content: center;
}
.h-100 {
	height: 100%;
}
.video-work {
	width: 100%;
	max-width: 100%;
	height: auto;
}

.bg-gray {
	background: rgba(0,0,0,0.02);
}
.bg-dark {
	background: $black;
	color: $white;

	a.secondary {
		color: rgba(255,255,255,0.6);

		&:hover {
			color: white;
			border-bottom: 2px solid rgba(0,0,0,0.1);
			border-color: currentColor;
		}
	}
}
.bg-light {
	background: rgba(255,255,255,0.6);
	color: $black;

	a.secondary {
		color: rgba(255,255,255,0.6);

		&:hover {
			color: white;
			border-bottom: 2px solid rgba(0,0,0,0.1);
			border-color: currentColor;
		}
	}
}
$kuma-color: #FFF2F4; //#E6F2FA
$kuma-color2: #FFF2F4;
$changeventures-color: #2D2A5B;
$changeventures-color2: #f6f5ff;
$herbier-color: #211D1A;
$herbier-color2: #413732;
$neurisium-color: #212239;
$neurisium-color2: #f3fbfa;
$procurement-color: #303030; //#eff7f1; //#fffaee; //#feefc3 #fede8a; //
$procurement-color2: #eff7f1; //#fffaee; //#feefc3 #fede8a; //
$procurement-color3: #fffaee;
$nodeswat-color: $black;
$crowdestate-color: #464d56; //#f6f8f9; //#5685c1;
$crowdestate-color2: #5685c1;


.bg-color {
	&--white { background-color: $body-background; color: $body-font-color; }
	&--changeventures { background-color: $changeventures-color; color: #fff; }
	&--changeventures2 { background-color: $changeventures-color2; color: $body-font-color; }
	&--herbier { background-color: $herbier-color; color: #fff; }
	&--herbier2 { background-color: $herbier-color2; color: #fff; }
	&--procurementflow { background-color: $procurement-color; color: #fff; }
	&--procurementflow2 { background-color: $procurement-color2; color: $body-font-color; }
	&--procurementflow3 { background-color: $procurement-color3; color: $body-font-color; }
	&--neurisium { background-color: $neurisium-color; color: #fff; }
	&--neurisium2 { background-color: $neurisium-color2; color: $body-font-color; }
	&--nodeswat { background-color: $nodeswat-color; color: #fff; }
	&--kuma { background-color: $kuma-color; color: $body-font-color; }
	&--kuma2 { background-color: $kuma-color2; color: $body-font-color; }
	&--crowdestate { background-color: $crowdestate-color; color: #fff; }
	&--crowdestate2 { background-color: $crowdestate-color2; color: #fff; }
	&--ocdesk { background-color: $body-background; color: $body-font-color; }
}
.bg-img {
	&--procurementflow {
		color: $body-font-color;
		background-image: url('../img/procflow/PF-graphics3-cropped.jpg');
		background-position: center right;
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-color: white;
	}
}
.text-gray {
	opacity: 0.6;
}
.text-lightgray {
	opacity: 0.8;
}
.text-orange {
	color: $orange;
}
.text-white {
	color: rgba(255,255,255,0.8);
}
.grayscale {
	filter: grayscale(1);
	transition: filter 0.5s;

	&:hover {
		filter: grayscale(0);
	}
}
p {
	word-spacing: .4px;
  letter-spacing: .1px;
  font-size: 100%;
}
p a {
	color: inherit;
	// font-size: 31px;
	// font-size: 100%;
	border-bottom: 2px solid rgba(0,0,0,0.1);
	border-color: currentColor;

	&:hover {
		color: inherit;
		opacity: 0.6;
		// border-bottom: 3px solid rgba(0,0,0,0.1);
		// border-color: currentColor;
	}
}
.max-h-90 {
	max-height: 90vh;
	// margin-left: auto;
	// margin-right: auto;
}
.min-h-fullfooter {
	min-height: calc(100vh - 150px);
}

.p-h-1 {
	padding-left: (map-get($grid-margin-gutters, small) / 2);
	padding-right: (map-get($grid-margin-gutters, small) / 2);

	@include breakpoint(medium) {
		padding-left: (map-get($grid-margin-gutters, medium) / 2);
		padding-right: (map-get($grid-margin-gutters, medium) / 2);
	}
}
.m-h-1 {
	margin-left: (map-get($grid-margin-gutters, small) / 2);
	margin-right: (map-get($grid-margin-gutters, small) / 2);

	@include breakpoint(medium) {
		margin-left: (map-get($grid-margin-gutters, medium) / 2);
		margin-right: (map-get($grid-margin-gutters, medium) / 2);
	}
}

.p-h-2 {
	padding-left: map-get($grid-margin-gutters, small);
	padding-right: map-get($grid-margin-gutters, small);

	@include breakpoint(medium) {
		padding-left: map-get($grid-margin-gutters, medium);
		padding-right: map-get($grid-margin-gutters, medium);
	}
}
.m-h-2 {
	margin-left: map-get($grid-margin-gutters, small);
	margin-right: map-get($grid-margin-gutters, small);

	@include breakpoint(medium) {
		margin-left: map-get($grid-margin-gutters, medium);
		margin-right: map-get($grid-margin-gutters, medium);
	}
}


.mt-05 {  margin-top: 0.5rem;   }
.mt-0 {  margin-top: 0rem;   }
.mt-1 {  margin-top: 1rem;   }
.mt-2 {  margin-top: 2rem;   }
.mt-3 {  margin-top: 3rem;   }
.mt-5 {  margin-top: 5rem;   }
.mt-10 {  margin-top: 10rem;   }

.mr-05 {  margin-right: 0.5rem;   }
.mr-0 {  margin-right: 0rem;   }
.mr-1 {  margin-right: 1rem;   }
.mr-2 {  margin-right: 2rem;   }
.mr-3 {  margin-right: 3rem;   }
.mr-5 {  margin-right: 5rem;   }

.mb-05 {  margin-bottom: 0.5rem;   }
.mb-0 {  margin-bottom: 0rem;   }
.mb-1 {  margin-bottom: 1rem;   }
.mb-2 {  margin-bottom: 2rem;   }
.mb-3 {  margin-bottom: 3rem;   }
.mb-5 {  margin-bottom: 5rem;   }

.ml-05 {  margin-left: 0.5rem;   }
.ml-0 {  margin-left: 0rem;   }
.ml-1 {  margin-left: 1rem;   }
.ml-2 {  margin-left: 2rem;   }
.ml-3 {  margin-left: 3rem;   }
.ml-5 {  margin-left: 5rem;   }

.p-05 {  padding: 0.5rem;   }
.p-0 {  padding: 0rem;   }
.p-1 {  padding: 1rem;   }
.p-2 {  padding: 2rem;   }
.p-3 {  padding: 3rem;   }
.p-5 {  padding: 5rem;   }

.pt-vw-12 {  padding-top: 12.5vw; }
.pr-vw-12 {  padding-right: 12.5vw; }
.pb-vw-12 {  padding-bottom: 12.5vw; }
.pl-vw-12 {  padding-left: 12.5vw; }

.pt-vw-7 {  padding-top: 7.5vw; }
.pr-vw-7 {  padding-right: 7.5vw; }
.pb-vw-7 {  padding-bottom: 7.5vw; }
.pl-vw-7 {  padding-left: 7.5vw; }

.pt-vw-2 {  padding-top: 2.5vw; }
.pr-vw-2 {  padding-right: 2.5vw; }
.pb-vw-2 {  padding-bottom: 2.5vw; }
.pl-vw-2 {  padding-left: 2.5vw; }

.p-l-0 { padding-left: 0 !important;  }
.p-r-0 { padding-right: 0 !important;  }

@media (min-width: 992px) {
	.md-p-l-0 { padding-left: 0 !important;  }
	.md-p-r-0 { padding-right: 0 !important;  }
}

// Small only
@media (max-width: 768px) {
  .small-only-mt-0 {  margin-top: 0rem;   }
  .small-only-mt-1 {  margin-top: 1rem;   }
  .small-only-mt-2 {  margin-top: 2rem;   }
  .small-only-mt-3 {  margin-top: 3rem;   }

  .small-only-mr-0 {  margin-right: 0rem;   }
  .small-only-mr-1 {  margin-right: 1rem;   }
  .small-only-mr-2 {  margin-right: 2rem;   }
  .small-only-mr-3 {  margin-right: 3rem;   }

  .small-only-mb-0 {  margin-bottom: 0rem;   }
  .small-only-mb-1 {  margin-bottom: 1rem;   }
  .small-only-mb-2 {  margin-bottom: 2rem;   }
  .small-only-mb-3 {  margin-bottom: 3rem;   }

  .small-only-ml-0 {  margin-left: 0rem;   }
  .small-only-ml-1 {  margin-left: 1rem;   }
  .small-only-ml-2 {  margin-left: 2rem;   }
  .small-only-ml-3 {  margin-left: 3rem;   }

  .small-only-text-small {
    font-size: 12px;
  }
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeout {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes heromain-animation {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 0;
	}
	90% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

.css-anim-fadein {
	animation: fadein 0.5s;
}
.js-anim-fadein {
	opacity: 0;
	transition: opacity 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;

	&.js-anim-fadein--active {
		opacity: 1;
	}
}

$burger-menu-color: #fff; //#c3c3c3;
$burger-menu-color-active: $white;

a.corner-nav-button {
	position: fixed;
	top: 0px;
  padding: 40px 40px 35px 40px;
  display: block;
  transition: background 0.2s cubic-bezier(0.77,0.2,0.05,1.0);
  // box-shadow: inset 0px 0px 0 white;
  cursor: pointer;

  @include breakpoint(small only) {
		padding: 20px 20px 15px 20px;
	}
	
  &:hover {
  	// backdrop-filter: blur(5px);
  	background-color: rgba(255,255,255,0.1);
  }
  &:active,
  &:focus {
  	// backdrop-filter: blur(0);
  	background-color: transparent;
  }

	&.backbutton {
		z-index: 98;
		color: $burger-menu-color;
		left: 0px;
    font-size: 26px;
    line-height: 26px;
    animation: fadein 0.5s;

		i.fa {
			transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0);
			text-shadow: 0 1px 1px rgba(0,0,0,0.2);
		}

		&:hover i.fa {
			// box-shadow: inset 5px 5px 0 white;
			transform: translateX(-3px);
		}
		&#backMenu {
			display: none;

			body.work-opened & {
				display: block;
			}
		}
	}
	&#menuToggle {
		z-index: 100;
		right: 0px;

		span {
		  display: block;
		  width: 27px;
		  height: 3px;
		  margin-bottom: 7px;
		  position: relative;
		  background: $burger-menu-color;
		  // border-radius: 3px;
		  box-shadow: 0 1px 1px rgba(0,0,0,0.2);
		  
		  transform-origin: 3px 0px;
		  
		  transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0),
		              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
		              opacity 0.55s ease;

		 //  &:before,
		 //  &:after {
			// 	content: '';
			// 	display: block;
			// 	width: 100%;
			// 	height: 100%;
			// 	position: absolute;
			// 	z-index: -1;
			// 	transition: transform 0.3s;
			// 	transform-origin: 30% 0;
			// 	opacity: 0.3;
			// }
		}
		// span:first-child {
		//   transform-origin: 0% 0%;
		// }
		// span:nth-last-child(2) {
		//   transform-origin: 0% 100%;
		// }
		&:hover {
			// box-shadow: inset -5px 5px 0 white;

			span {
			  transform: translateY(2px);
			}
			span:nth-last-child(2) {
				transform: translateY(0px);
			}
			span:nth-last-child(3) {
		  	transform: translateY(-2px);
			}
		}

		&.active {
			span {
			  opacity: 1;
			  transform: rotate(-45deg) translate(3px, -2px);
			  background: $burger-menu-color-active;
			}
			span:nth-last-child(2) {
			  opacity: 0;
			  transform: rotate(0deg) scale(0.2, 0.2);
			}
			span:nth-last-child(3) {
		  	transform: rotate(45deg) translate(3px, -1px);
			}
		}
	}
}
.nav-layover {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(35,35,35,0.8);
	// backdrop-filter: blur(7px);
	animation: fadein 0.3s;
	z-index: 99;
	color: $white;
	display: none;
	
	&.active {
		display: block;
	}

	.nav-holder {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.nav-logo {
		width: 100%;
		max-width: 300px;
		// margin-bottom: 30px;
		display: block;
		margin-bottom: 2.222rem;

		path {
			fill: white;
		}

		@include breakpoint(small only) {
			max-width: 250px;
			margin-top: 2.222rem;
			margin-bottom: 1.111rem;
		}
	}
	p {
		color: $white;
		font-size: 120%;

		@include breakpoint(small only) {
		 font-size: 100%;
		}
	}
	#menu {
		display: block;
		margin: 0;
		list-style: none;
		text-align: center;

		li {
			margin-bottom: 15px;
		}

		a {
			font-size: 120%;
			color: rgba(255,255,255,0.6);
			display: inline-block;
			padding-left: 0.7rem;
			padding-right: 0.7rem;

			i.fa {
				font-size: 150%;
			}

			&:hover {
				color: white;
			}
		}
	}
}


.homepage-hero {
	// transition: height 0.5s;
	cursor: pointer;
	// background: black;
	color: white;
	height: 100vh;

	@include breakpoint(small only) {
		max-height: 165vw;

		canvas {
			pointer-events: none;
		}
	}


	body.work-opened & {
		display: none;
	}

	&.homepage-hero--about {
		height: 70vh;
	}

	#hero-footer {
		position: absolute;
		bottom: -9.37vw;
		left: 0;
		z-index: 11;
		height: 18.75vw;
		width: 100%;
		// box-shadow: 0 0 0 1px red;
	}

	.grid-y--center {
		height: 100%;
	}

	.video-frame {
		position: relative;
		width: 100%;
		height: 100%;
	}
	#hero-logo-container {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 12;
		
		.loading-container {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		canvas {
			position: absolute;
		}
		&.hidden {
			display: none;
		}
	}
}

.video-background {
	position: relative;
	// top: 0;
	// left: 0;
	width: 100%;
	height: 100%;
	// z-index: 5;
	overflow: hidden;
	// filter: grayscale(100%) brightness(30%);
	// mix-blend-mode: difference;
	// background: black;

	.video-overlay-dark {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		// background: linear-gradient(
	 //    45deg,
		//     hsla(0, 0%, 0%, 1) 0%,
		//     hsla(0, 0%, 0%, 0.5) 50%,
		//     hsla(0, 0%, 0%, 1) 100%
	 //  	);
		// background-position: top left;
		opacity: 0.2;
		background: black;
		// z-index: 6;
	}

	video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;

    @media only screen and (min-height: 56.25vw) {
    	height: 100%;
    }
    @media only screen and (max-height: 56.25vw) {
    	width: 100%;
    }
	}
}
.hero-secondary {
	max-width: 2000px;
	width: 85vw;
	padding: 3.333rem 0;
	margin: 0 auto;
	// background: #000;
	// color: $black;
	// position: relative;
	// margin-top: 10vh;

	body.work-opened & {
		display: none;
	}

	&.hero-secondary--video {
		// margin: 0 5%;
		// color: $white;
		// min-height: 36vw;
	}
	
	.hero-secondary-content {
		z-index: 2;
		// position: absolute;
		min-height: 40vw;
		width: 100%;
	}
	h1 {
		font-weight: 500;
		// text-transform: uppercase;
		// letter-spacing: 0.1em;

		@include breakpoint(large) {
			// font-size: 6vw;
			font-size: 10vw;
		}
	}
	p.lead {
		// max-width: 2000px;
		// width: 75vw;
		// opacity: 0.6;

		@include breakpoint(large) {
		}
	}

	&.hero-secondary--video:before {
		// content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 38vh;
		width: 100%;
		z-index: 2;
    background: linear-gradient(
	    to bottom,
		    hsla(0, 0%, 100%, 0) 0%,
		    hsla(0, 0%, 100%, 0.013) 8.1%,
		    hsla(0, 0%, 100%, 0.049) 15.5%,
		    hsla(0, 0%, 100%, 0.104) 22.5%,
		    hsla(0, 0%, 100%, 0.175) 29%,
		    hsla(0, 0%, 100%, 0.259) 35.3%,
		    hsla(0, 0%, 100%, 0.352) 41.2%,
		    hsla(0, 0%, 100%, 0.45) 47.1%,
		    hsla(0, 0%, 100%, 0.55) 52.9%,
		    hsla(0, 0%, 100%, 0.648) 58.8%,
		    hsla(0, 0%, 100%, 0.741) 64.7%,
		    hsla(0, 0%, 100%, 0.825) 71%,
		    hsla(0, 0%, 100%, 0.896) 77.5%,
		    hsla(0, 0%, 100%, 0.951) 84.5%,
		    hsla(0, 0%, 100%, 0.987) 91.9%,
		    hsl(0, 0%, 100%) 100%
	  	);
	}
}
h2 {
	font-weight: bold;
	// font-size: 56px;
	font-size: 233%;
	margin-bottom: 50px;

	@include breakpoint(small only) {
		font-size: 180%;
	}
}
h3 {
	font-size: 134%;
}
.index-cta {
	display: block;
	padding: 220px 10vw;
	// height: 100vh;
	// width: 100vw;
	position: relative;
	overflow: hidden;
	color: #fff;

	@include breakpoint(small only) {
		padding: 120px 10vw;
	}

	&:hover {
		color: #fff;
	}

	body.work-opened & {
		display: none;
	}

	.grid-y {
		pointer-events: none;
	}

	#footer-cta-canvas {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		background: rgba(50,50,50,1);
	}

	p.lead {
		// font-weight: bold
	}
}
p.lead {
	font-weight: 400;
	// max-width: 2000px;
	// width: 75vw;
	margin: 0 auto;
	font-size: 155%;

	@include breakpoint(small only) {
		font-size: 130%;
	}

	a {
		color: inherit;
		// font-size: 31px;
		font-size: 100%;
		// border-bottom: 3px solid rgba(0,0,0,0.1);

		&:hover {
			border-bottom: 3px solid rgba(0,0,0,0.1);
			border-color: currentColor;
		}
	}
}
.color-pink {
	background: pink;
}
#index-works {
	// padding-top: 50px;
	// padding-bottom: 50px;
	margin: 0 auto;
	width: 100%;
	// transition: width 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;

	// .index-work-item {
	// 	margin-bottom: 6.666rem;
		
	// 	&:last-of-type {
	// 		margin-bottom: 0;
	// 	}
	// }

	.work-panel {
		margin-bottom: 6.666rem;
		position: relative;

		@include breakpoint(small only) {
			margin-bottom: 3.333rem;
		}

		.work-preview-container {
			position: relative;
			// margin-top: 0vh;
			margin: 0 auto;
			// max-width: 125vh;
			max-width: 2000px;
			width: 75vw;
			will-change: width;
			transition: width 0.7s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;

			@include breakpoint(small only) {
				width: 85vw;
			}

			body.work-opened & {
				width: 100%;
				max-width: auto;
			}

			.work-preview-tilt {
				transition: transform 0.5s;
				
				@include breakpoint(medium) {
					&:before {
						content: 'Case Study →';
						color: rgba(150,150,150,1);
						// text-shadow: 0 1px 2px rgba(50,50,50,0.2);
						padding: 17px 25px 15px 30px;
						backdrop-filter: blur(5px);
						background-color: rgba(250,250,250,0.15);
						position: absolute;
						bottom: 0.5px;
						right: 0;
						z-index: 9;
						transition: opacity 0.5s cubic-bezier(0.6, 0.2, 0.1, 1);
						display: block;
						opacity: 0;
					}

					&:hover {
						transform: scale(1.03);

						&:before {
							opacity: 1;
						}
					}
				}
			}

			.work-preview {
				display: block;
				cursor: pointer;
				width: 100%;
				// max-width: 65vw;
				// height: 56vw;
				// max-height: 70vh;
				padding-bottom: 56%;
				margin: 0 auto;
				// background: $black;
				// margin-bottom: 30px;
				position: relative;
				overflow: hidden;
				// will-change: transform;
				// transform: scale(1);
				// transition: transform 0.5s;
				// box-shadow: 0 0 50px rgba(0,0,0,0.2;
				background: black;

				video {
					// box-shadow: 0 0 50px rgba(0,0,0,0.1);
					// visibility: hidden;
					// display: none;
					// transition: transform 0.5s;
				}

				// &:before {
				// 	// content: '';
				// 	position: absolute;
				// 	z-index: 9;
				// 	will-change: border-width;
				// 	transition: border-width 0.5s cubic-bezier(0.6, 0.2, 0.1, 1);
				// 	border: 0.3rem solid black;
				// 	width: 100%;
				// 	height: 100%;
				// 	display: block;
				// }
				

				// body.bg-color--white &:before { box-shadow: inset 0 0 0 5px $body-background; }
				// body.bg-color--white &:hover:before { box-shadow: inset 0 0 0 0px $body-background; } 

				// body.bg-color--changeventures &:before { box-shadow: inset 0 0 0 5px $changeventures-color; }
				// body.bg-color--changeventures &:hover:before { box-shadow: inset 0 0 0 0px $changeventures-color; } 
				
				// body.bg-color--herbier &:before { box-shadow: inset 0 0 0 5px $herbier-color; }
				// body.bg-color--herbier &:hover:before { box-shadow: inset 0 0 0 0px $herbier-color; } 
				
				// body.bg-color--procurementflow &:before { box-shadow: inset 0 0 0 5px $body-background; }
				// body.bg-color--procurementflow &:hover:before { box-shadow: inset 0 0 0 0px #fffaee; } 
				
				// body.bg-color--neurisium &:before { box-shadow: inset 0 0 0 5px #212239;  }
				// body.bg-color--neurisium &:hover:before { box-shadow: inset 0 0 0 0px #212239; } 
				
				// body.bg-color--nodeswat &:before { box-shadow: inset 0 0 0 5px $body-background;  }
				// body.bg-color--nodeswat &:hover:before { box-shadow: inset 0 0 0 0px $body-background; } 
				
				// body.bg-color--kuma &:before { box-shadow: inset 0 0 0 5px $kuma-color;  }
				// body.bg-color--kuma &:hover:before { box-shadow: inset 0 0 0 0px $kuma-color; } 
				
				// body.bg-color--crowdestate &:before { box-shadow: inset 0 0 0 5px #f6f8f9;  }
				// body.bg-color--crowdestate &:hover:before { box-shadow: inset 0 0 0 0px #f6f8f9; } 
				
				// body.bg-color--ocdesk &:before { box-shadow: inset 0 0 0 5px $body-background;  }
				// body.bg-color--ocdesk &:hover:before { box-shadow: inset 0 0 0 0px $body-background; } 

				// &:after {
				// 	content: 'Case study coming soon';
				// 	position: absolute;
				// 	z-index: 9;
				// 	transition: all 0.5s;
				// 	display: block;
				// 	right: 7%;
				// 	top: 7%;
				// 	color: rgba(155,155,155,0.9);
				// 	text-shadow: 0 1px 3px rgba(255,255,255,0.1);
				// 	opacity: 0;
				// }
				// &:hover:after {
				// 	opacity: 1;
				// }

				video.video-js {
					position: absolute;
					top: -1%;
					left: 50%;
					transform: translateX(-50%);
					height: 102%;
					width: auto;
					transition: all 0.5s;
					// filter: grayscale(100%) brightness(70%);
				}
				> img {
					position: absolute;
					top: 0%;
					left: 50%;
					transform: translateX(-50%);
					height: 100%;
					width: auto;
				}
				&.work-preview--procurementflowimg {
					background: $procurement-color;
					> img {
						margin: 10px;
					}
				}
				.work-preview-img {
				}
				.work-preview-logo {
				}
			}
		}		
		// &--white .work-preview { box-shadow: 0 0 30px $white;  } 
		// &--changeventures .work-preview { box-shadow: 0 0 30px $changeventures-color;  }
		// &--herbier .work-preview { box-shadow: 0 0 30px $herbier-color;  }
		// &--procurement .work-preview { box-shadow: 0 0 30px $procurement-color;  }
		// &--neurisium .work-preview { box-shadow: 0 0 30px $neurisium-color;  }
		// &--nodeswat .work-preview { box-shadow: 0 0 30px $nodeswat-color;  }
		// &--kuma .work-preview { box-shadow: 0 0 30px $kuma-color;  }
		// &--crowdestate .work-preview { box-shadow: 0 0 30px $crowdestate-color;  }

		.work-panel-content {
			padding: 5vh 0;
			max-width: 2000px;
			width: 75vw;
			margin: 0 auto;
			@include breakpoint(small only) {
				width: 85vw;
			}
		}
		.work-details-container {
			// margin-top: 7.5vw;

			p.lead {
				margin-bottom: 1em;
			}
		}
		// .work-title-container {
		// 	margin-top: 7vw;
		// }
		.work-details-container,
		.work-title-container {
			display: none;
		}
		// h3 {
		// 	// padding-top: 10vh;
		// 	font-weight: bold;
		// 	// font-size: 48px;
		// 	font-size: 233%;
		// 	margin-top: 1rem;
		// 	margin-bottom: 1rem;

		// 	img {
		// 		max-width: 350px;
		// 	}

		// 	a {
		// 		color: inherit;

		// 		&:hover {
		// 			border-bottom: 3px solid rgba(0,0,0,0.1);
		// 			border-color: $black;
		// 		}
		// 	}
		// }

		.work-subtext {
		// 	color: inherit;
		// 	opacity: 0.5;
			margin-bottom: 1.6em;
		}
		.work-maintext {
			margin-bottom: 0;
			// margin-bottom: 2.222rem;
			opacity: 1;
			// padding-right: 5.555rem;
			// transition: all 1s;
		}
		.mobile-casestudylink {
			display: none;
			text-decoration: none;
			color: #fff;

			@include breakpoint(small only) {
				display: block;
				margin-top: 1rem;
				opacity: 0.6;
			}
		}
		.quote-container {
			// @media (min-width: 768px) {
			// 	margin-top: 2.222rem;
			// }
			// background: rgba(0,0,0,0.015);
		}
		.work-logo-preview {
			height: 0;
			padding-bottom: 46%;
			width: 100%;
			position: relative;

			.grid-y {
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				top: 0;
			}

			img {
				margin: 0 auto;
				display: block;
				width: 100%;
				max-width: 45%;
			}
		}
		.work-quote {
			// border-left: 5px solid rgba(150,150,150,0.1);
			// padding-left: 1.111rem;
			// padding-left: 2.222rem;
			// padding-left: 5.555rem;
			// min-height: calc(50vw - 2.08333rem - 2.08333rem - 2.08333rem);
			// min-height: calc(50vw - 2.08333rem);
			color: inherit;
			// transform: translateY(30px);
			// transition: transform 0.5s, opacity 0.5s;
			// border-left: 5px solid rgba(150,150,150,0.2);
			// padding: 2.222rem 0;

			// display: none;
			// .cell {
			// 	padding: 3.333rem 2.222rem;
			// }
			p {
				margin-bottom: 0;
			}
			q {
				// opacity: 0.5;
				// font-style: italic;
				font-weight: normal;
				display: block;
				// background: $black;
				// padding: 0;
				// font-size: 21px;
				margin-bottom: 0rem;
				margin-top: 2.222rem;
				border-left: 5px solid rgba(150,150,150,0.2);
				padding: 0 1rem;

				&:before {
					color: rgba(150,150,150,0.2);
					font-size: 140%;
					// font-family: 'Georgia', serif;
				 	content: open-quote;
				 	line-height: 1;
				 	// display: none;
				}
				&:after {
					color: rgba(150,150,150,0.2);
					font-size: 140%;
					// font-family: 'Georgia', serif;
				 	content: close-quote;
				 	line-height: 1;
				 	// display: none;
				}
			}
			.q-avatar {
				height: 60px;
				width: 60px;
				line-height: 60px;
				border-radius: 100%;
				margin-right: 5px;
				box-shadow: 0 0 0 1px rgba(150,150,150,0.1);
			}
			.q-name {
				display: inline-block;
				line-height: 32px;
				vertical-align: middle;
				
				.text-gray {
					// font-size: 18px;
				}
			}
			// &:hover {
			// 	transform: scale(1.02);
			// }
		}
		.end-work-selection {
			border-top: 2px solid black;
			display: flex;
			text-align: center;


			display: none;

			a.work-previous, 
			a.work-next {
				width: 50%;
				padding: 2.222rem 12.5vw;
				color: white;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
			.work-previous {
				border-right: 1px solid black;
			}
			.work-next {
				border-left: 1px solid black;
			}
			h3 {
				margin-bottom: 0;
			}
			p {
				@extend .text-gray;
				// margin-bottom: 0;
			}
		}
		&.work-panel--opened {
			margin-bottom: 0;

			&.js-anim-fadein {
				opacity: 1 !important;
			}
			// position: absolute;
			// top: 0;
			// left: 0;
			// width: 100%;
			.work-preview {
				box-shadow: 0 0 0px rgba(0,0,0,1);
			}
			.work-preview-tilt:hover {
				transform: none !important;
			}
			.work-panel-content {
				display: none;
				animation: fadeout 1s;
				opacity: 0 !important;
			}
			// .work-title-container,
			.work-details-container {
				animation: fadein 2s;
				// animation-delay: 1s;
				display: block;
			}
		}
		&.work-panel--active {
			.work-preview {
				transform: scale(1.02);
			}
		}
	}
	.cell {
		
	}
}
.neurisium-video {
	.video-overlay {
		left: 0;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  z-index: 8;
	  background-color: rgba(33,34,57,.5);
	}
	.hero-shape {
		bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
	}
	.hex, .hex-1, .hex-2, .hex-3 {
    position: absolute;
	}
	.hex {
    overflow: hidden;
    width: 100%;
    height: 60vw;
    max-height: 60vh;
    transform: rotate(-60deg);
  }
  .hex-1, .hex-2 {
    left: -50%;
    width: 200%;
    height: 100%;
    overflow: hidden;
	}
	.hex-1 {
    transform: rotate(60deg);
	}
	.hex-2 {
    transform: rotate(-120deg);
  }
  .hex-3 {
    top: -10%;
    right: -10%;
    display: -webkit-flex;
    display: flex;
    width: 120%;
    height: 120%;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: flex-start;
    background-color: hsla(0,0%,100%,.2);
    transform: rotate(120deg);
	}
	.hex-inner-video-holder {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100vw;
    height: 100vh;
    max-height: 105%;
    transform: translate(-50%,-50%) scale3d(1,-1,1);
    transform-style: preserve-3d;
    // transition: top 1s;
    will-change: top;
	}
	.hero-video-reverse {
    height: 100%;
	}
	.neurisium-bg-video {
		position: absolute;
    left: 50%;
    top: 45%;
    width: 100vw;
    height: 100vh;
    max-height: 110%;
    transform: translate(-50%,-50%);
    // transition: top 1s;
    will-change: top;
	}
	.neurisium-bg-video-inner {
		position: relative;
    height: 100%;
	}
	.w-background-video {
    position: relative;
    height: 100%;
    overflow: hidden;
	}
}
.work-details-content {
	// padding: 5vh 0;
	max-width: 2000px;
	width: 75vw;
	margin: 0 auto;
	@include breakpoint(small only) {
		width: 85vw;
	}
}
.work-details-content-padding {
	padding-top: 7.5vw;
	padding-bottom: 7.5vw;
}
.work-details-inter-padding {
	padding-top: 2.5vw;
	padding-bottom: 2.5vw;
}
#about-page,
#jobs-page {
	@include breakpoint(small only) {
		padding-top: 3rem;
	}
}
.laptop-video-holder {
	height: 0;
	padding-bottom: 75%;
	position: relative;
	width: 100%;
	overflow: hidden;
	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.laptop-shape {
	padding: 4%;
	box-shadow: 0 0 0 2px #555;
  border-top-left-radius: 3% 4%;
  border-top-right-radius: 3% 4%;
  position: relative;
  margin-bottom: 5%;
  margin-top: 3%;
  
  @include breakpoint(small only) {
  	width: 80%;
  	margin-left: auto;
  	margin-right: auto;
  }

  &:after {
  	content: '';
  	display: block;
  	position: absolute;
  	bottom: -3%;
  	margin-bottom: -2px;
  	left: -10%;
  	right: -10%;
  	height: 3%;
  	box-shadow: 0 0 0 2px #555;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 10em;
    border-bottom-right-radius: 10em;
  }
}
footer {
	height: 150px;
	line-height: 150px;
	background: black;

	a {
		display: inline-block;
		height: 150px;
		color: rgba(255,255,255,0.8);
		// border-bottom: 2px solid rgba(0,0,0,0.1);

		& + a {
			margin-left: 50px;

			@include breakpoint(small only) {
				margin-left: 20px;
			}
		}
		&:hover,
		&:focus {
			// text-shadow: 1px 2px 3px #FF00FF, -1px -2px 3px #00FF00;
			color: rgba(255,255,255,1);
			border-bottom: 4px solid white;
			// background: rgba(255,255,255,0.1);
		}
	}
	svg#logo-saturnist {
		width: 250px;
    vertical-align: middle;

		.cls-1{fill:#fff;} 
	}
	svg.svg-monument {
		display: block;
		margin: -105px auto 0 auto;
		height: 160px;

		.monument-st0 {
			fill:rgba(0,0,0,0.6);
		}
		.monument-st1 {
			svg { 
				fill: lightgray;	
			} //black;
		}

	}
}






.content {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(100vh - 13rem);
	position: relative;
	justify-content: flex-start;
	align-items: center;
}

.content__img-wrap {
	position: relative;
	width: 80%;
	height: calc(100% - 13rem);
}

.content__img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(../img/example.png) no-repeat 50% 100%;
	background-size: cover;
	opacity: 0.7;
	will-change: transform;
}

.content__img:nth-child(1) {
	opacity: 0.3;
}

.content__img:nth-child(2) {
	opacity: 0.5;
}

.content__text {
	position: absolute;
	font-size: 10vw;
}

.js .content__text-inner {
	opacity: 0;
}


/////////////// ---- displacement


/* Page Loader */
.js .loading::before {
	content: '';
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	content: '';
	position: fixed;
	z-index: 100000;
	top: 50%;
	left: 50%;
	width: 50px;
	height: 50px;
	margin: -25px 0 0 -25px;
	pointer-events: none;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}


.displacement-holder {
  height: 100%;
  width: 100vw;
  position: relative;
}

.grid__item {
	position: relative;
	width: 100%;
}


.grid__item-img {
	display: block;
	height: 100vh;
	width: 100vw;
  overflow: hidden;
  position: relative;
}

.grid__item-img canvas {
	height: 100%;
	// width: 100vw;
	position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 169vh !important;
}

.grid__item-img img {
	height: 100%;
	display: block;
	// width: 100vw;
	position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 169vh !important;
}

.grid__item-img img:nth-child(2) {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: opacity 0.3s;
}

.grid__item:hover .grid__item-img img:nth-child(2) {
	// opacity: 1;
}

.js .grid__item-img img {
	display: none;
}





.particlebuttonholder {
	position: relative;
}
.particles {
	position: relative;
	grid-area: 1 / 1 / 2 / 2;
}

.particles-canvas {
	position: absolute;
	pointer-events: none;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%,-50%,0);
}

.particles-wrapper {
	position: relative;
	display: inline-block;
	overflow: hidden;
	will-change: transform;
	width: 100%;
}

.particles-button {
	user-select: none;
	position: relative;
	border-radius: 5px;
	border: 0;
	margin: 0;
	padding: 1.5rem 3rem;
	will-change: transform;
}

.particles-button:focus {
	outline: none;
}

.no-js .particles-button {
	grid-area: 1 / 1 / 2 / 2;
}

.action {
	position: absolute;
	top: 0;
	right: 0;
	background: none;
	margin: 2rem;
	border: 0;
	padding: 0;
	opacity: 0;
	pointer-events: none;
}

.action:focus {
	outline: none;
}

.hidden {
	position: absolute;
	overflow: hidden;
	width: 0;
	height: 0;
	pointer-events: none;
}


///---- loader

.loader,
.loader:after {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 8px solid rgba(0, 0, 0, 1);
  border-right: 8px solid rgba(0, 0, 0, 1);
  border-bottom: 8px solid rgba(0, 0, 0, 1);
  border-left: 8px solid rgba(255,255,255, 1);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.5s infinite linear;
  animation: load8 1.5s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
